// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store.getState().blockchain
      let paused = await store.getState().blockchain.smartContract.methods.paused().call();
      let totalSupply = await store.getState().blockchain.smartContract.methods.totalSupply().call();
      let maxMintAmountPerTransaction = await store.getState().blockchain.smartContract.methods.maxMintAmountPerTransaction().call();
      let whitelistUserAmount = await store.getState().blockchain.smartContract.methods.whitelistUserAmount(blockchain.account).call()
      let whitelistMintedAmount = await store.getState().blockchain.smartContract.methods.whitelistMintedAmount(blockchain.account).call()
      let publicSaleMintedAmount = await store.getState().blockchain.smartContract.methods.publicSaleMintedAmount(blockchain.account).call()
      let onlyWhitelisted = await store.getState().blockchain.smartContract.methods.onlyWhitelisted().call()
      let mintCount = await store.getState().blockchain.smartContract.methods.mintCount().call()
      let publicSaleMaxMintAmountPerAddress = await store.getState().blockchain.smartContract.methods.publicSaleMaxMintAmountPerAddress().call()

      dispatch(
        fetchDataSuccess({
          totalSupply,
          paused,
          maxMintAmountPerTransaction,
          whitelistUserAmount,
          whitelistMintedAmount,
          publicSaleMintedAmount,
          onlyWhitelisted,
          mintCount,
          publicSaleMaxMintAmountPerAddress,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
