const initialState = {
  loading: false,
  paused: false,
  totalSupply: 0,
  maxMintAmountPerTransaction: 10,
  whitelistUserAmount: 5,
  whitelistMintedAmount: 5,
  publicSaleMintedAmount:0,
  onlyWhitelisted: true,
  mintCount: true,
  publicSaleMaxMintAmountPerAddress: 100,
  cost: 0,
  error: false,
  errorMsg: "",
  isWhitelisted: false,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        paused: action.payload.paused,
        totalSupply: action.payload.totalSupply,
        maxMintAmountPerTransaction: action.payload.maxMintAmountPerTransaction,
        whitelistUserAmount: action.payload.whitelistUserAmount,
        whitelistMintedAmount: action.payload.whitelistMintedAmount,
        publicSaleMintedAmount: action.payload.publicSaleMintedAmount,
        onlyWhitelisted: action.payload.onlyWhitelisted,
        mintCount: action.payload.mintCount,
        publicSaleMaxMintAmountPerAddress: action.payload.publicSaleMaxMintAmountPerAddress,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
